<template>
  <div>
    <van-nav-bar
      title="添加地块"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
    <van-form @submit="onSubmit">
      <div style="display: flex;">
        <div style="margin: 15px; font-size:14px; color:#646566">地图选点</div>
        <div style="margin: 15px; font-size:14px; color:#646566" @click="Mymap">
          点击这里
        </div>
      </div>
      <van-cell-group>
        <van-field
          v-model="farmlandName"
          label="地块名称"
          placeholder="请输入地块名称"
          :rules="[{ required: true, message: '不能为空' }]"
        />
      </van-cell-group>
      <van-field
        readonly
        clickable
        name="irrigation"
        :value="irrigation"
        label="灌溉条件"
        placeholder="灌溉条件"
        @click="showIrrigation = true"
      />
      <van-popup v-model="showIrrigation" position="bottom">
        <van-picker
          show-toolbar
          :columns="irrigationData"
          value-key="name"
          @confirm="onIrrigation"
          @cancel="showIrrigation = false"
        />
      </van-popup>
      <van-cell-group class="farmland-unit">
        <van-field
          v-model="farmlandArea"
          label="地块面积"
          placeholder="请输入地块面积"
          :rules="[{ required: true, message: '不能为空' }]"
        />
        <div class="unit">亩</div>
      </van-cell-group>
      <van-cell-group>
        <van-field
          v-model="contactPerson"
          label="联系人"
          placeholder="请输入联系人"
        />
      </van-cell-group>
      <van-cell-group>
        <van-field
          v-model="contactNumber"
          label="联系电话"
          placeholder="请输入联系电话"
        />
      </van-cell-group>
      <van-cell-group>
        <van-field label="地块照片" />
        <van-uploader v-model="fileList" multiple :max-count="1" />
      </van-cell-group>
      <div style="width:90%; margin: 10px auto">
        <van-button round block type="info" native-type="submit" style="background:#001f90"
          >提交</van-button
        >
      </div>
    </van-form>
  </div>
</template>
<script>
import { getProvince, getCity, getCountry } from "@/service/areaService.js";
import {
  getCropList,
  postSeveframlandList,
  getLocation
} from "@/service/Addfarmland.js";
import { handleAreaData } from "@/assets/js/util.js";
import {
  NavBar,
  Form,
  Field,
  CellGroup,
  Popup,
  Picker,
  Button,
  Dialog,
  Notify,
  Uploader
} from "vant";
export default {
  name: "AddFarmland",
  data() {
    return {
      farmlandName: "",
      farmlandArea: "",
      contactPerson: "",
      contactNumber: "",
      fieldValue: "",
      cascaderValue: "",
      provinceCode: "",
      cityCode: "",
      countyCode: "",
      crops: "",
      crops_id: "",
      season: "",
      season_id: "",
      breed: "",
      breed_id: "",
      irrigation: "",
      irrigation_id: "",
      fieldNames: {
        text: "name",
        value: "code",
        children: "items"
      },
      fileList: [],
      options: [],
      irrigationData: [],
      seasonData: [],
      KiwicropSeasonList: "",
      KiwicropTypeList: "",
      TobaccoleafCropSeasonList: "",
      TobaccoleafCropTypeList: "",
      breedData: [],
      cropsData: [],
      data: {},
      lonlat: "",
      showCrops: false,
      showSeason: false,
      showBreed: false,
      showIrrigation: false,
      show: false
    };
  },
  components: {
    VanNavBar: NavBar,
    VanField: Field,
    VanCellGroup: CellGroup,
    VanPopup: Popup,
    VanPicker: Picker,
    VanButton: Button,
    // VanCascader: Cascader,
    VanForm: Form,
    VanUploader: Uploader,
    [Dialog.Component.name]: Dialog.Component,
    [Notify.Component.name]: Notify.Component
  },
  created() {
    getProvince().then(res => {
      const provinceList = handleAreaData(res.data);
      this.options = provinceList;
    });
  },
  mounted() {
    this.getCrop();
    this.getCityList();
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    // onConfirm(crops) {
    //   this.crops = crops.name;
    //   this.crops_id = crops.id;
    //   this.showCrops = false;
    //   if (this.crops_id === 1) {
    //     this.seasonData = this.KiwicropSeasonList;
    //     this.breedData = this.KiwicropTypeList;
    //   } else if (this.crops_id === 2) {
    //     this.seasonData = this.TobaccoleafCropSeasonList;
    //     this.breedData = this.TobaccoleafCropTypeList;
    //   }
    // },
    // onSeason(season) {
    //   this.season = season.name;
    //   this.season_id = season.id;
    //   this.showSeason = false;
    // },
    // onBreed(breed) {
    //   this.breed = breed.name;
    //   this.breed_id = breed.id;
    //   this.showBreed = false;
    //   // this.getBreed();
    // },
    onIrrigation(irrigation) {
      this.irrigation = irrigation.name;
      this.irrigation_id = irrigation.id;
      this.showIrrigation = false;
    },
    onChange({ value, selectedOptions, tabIndex }) {
      if (tabIndex === 0) {
        // 选择第一层省
        getCity(value).then(res => {
          const cityList = handleAreaData(res.data);
          const index = this.options.findIndex(e => {
            return e.value === value;
          });
          this.countryIndex = index;
          this.options[index].children = cityList;
        });
      } else if (tabIndex === 1) {
        // 选择第二层市
        getCountry(value).then(res => {
          const countryList = handleAreaData(res.data);
          const index = this.options[this.countryIndex].children.findIndex(
            e => {
              return e.value === value;
            }
          );
          this.cityIndex = index;
          this.options[this.countryIndex].children[
            index
          ].children = countryList;
        });
      }
    },
    onFinish({ value, selectedOptions, tabIndex }) {
      this.show = false;
      this.fieldValue = selectedOptions.map(option => option.text).join("/");
      this.areaValue = value;
      this.provinceCode = selectedOptions[0].value;
      this.cityCode = selectedOptions[1].value;
      this.countyCode = selectedOptions[2].value;
    },
    getCrop() {
      getCropList().then(res => {
        this.irrigationData = res.data;
      });
    },
    getBreed() {},
    Mymap() {
      this.$store.commit("setfarmlandName", this.farmlandName);
      // this.$store.commit("setirrigation", this.irrigation)
      this.$store.commit("setirrigation", this.irrigation);
      this.$store.commit("setirrigation_id", this.irrigation_id);
      this.$store.commit("setfarmlandArea", this.farmlandArea);
      this.$store.commit("setcontactPerson", this.contactPerson);
      this.$store.commit("setcontactNumber", this.contactNumber);
      this.$store.commit("setfileList", this.fileList);
      this.$router.push({
        path: "/addMap"
      });
    },
    getCityList() {
      this.lonlat = this.$store.state.lng + "," + this.$store.state.lat;
      this.farmlandName = this.$store.state.farmlandName;
      this.irrigation = this.$store.state.irrigation;
      this.irrigation_id = this.$store.state.irrigation_id;
      this.farmlandArea = this.$store.state.farmlandArea;
      this.contactPerson = this.$store.state.contactPerson;
      this.contactNumber = this.$store.state.contactNumber;
      this.fileList = this.$store.state.fileList;
      if (this.lonlat === "") {
        this.fieldValue = "";
      } else {
        getLocation(this.lonlat).then(res => {
          if (res.data.province === null) {
            res.data.province = "";
          }
          if (res.data.city === null) {
            res.data.city = "";
          }
          if (res.data.county === null) {
            res.data.county = "";
            this.fieldValue = "";
          } else {
            this.fieldValue =
              res.data.province + "/" + res.data.city + "/" + res.data.county;
            this.provinceCode = res.data.provinceCode;
            this.cityCode = res.data.cityCode;
            this.countyCode = res.data.countyCode;
          }
        });
      }
    },
    onSubmit(values) {
      if (values === "") {
        Dialog.confirm({
          title: "提交地块表单失败",
          message: "请重新提交"
        }).catch(() => {
          // on cancel
        });
      } else {
        Dialog.confirm({
          title: "提交地块表单",
          message: "确定提交？"
        }).then(() => {
          const param = new FormData();
          for (var i = 0; i < this.fileList.length; i++) {
            param.append("image", this.fileList[i].file);
          }
          param.append("name", this.farmlandName);
          param.append("area", this.farmlandArea);
          param.append("linkman", this.contactPerson);
          param.append("linkman_phone", this.contactNumber);
          param.append("province", this.provinceCode);
          param.append("city", this.cityCode);
          param.append("county", this.countyCode);
          param.append("irrigation", this.irrigation_id);
          param.append("longitude", this.$store.state.lng);
          param.append("latitude", this.$store.state.lat);
          postSeveframlandList(param).then(res => {
            if (res.code === 200) {
              this.lonlat = "";
              this.$router.push({
                path: `/farmlandlist`
              });
            }
          });
        });
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.farmland-unit {
  position: relative;
  .unit {
    position: absolute;
    top: 3.66667vw;
    left: 85.33333vw;
  }
}
</style>
